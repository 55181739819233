import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(3 3)">
      <polygon
        id="Shape"
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="39 0 0 22 0 67 39 90 78 68 78 23"
      />
    </g>
    <g transform="translate(1.406758-2.212557)">
      <line
        x1="0"
        y1="-20.588361"
        x2="0"
        y2="20.588362"
        transform="translate(30.011423 50.212559)"
        stroke="currentColor"
        strokeWidth="7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="-11.771486"
        y1="11.498211"
        x2="11.771486"
        y2="-11.498211"
        transform="translate(42 41.122409)"
        stroke="currentColor"
        strokeWidth="7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="-10.610411"
        y1="-10.294181"
        x2="10.610411"
        y2="10.294181"
        transform="translate(43.161075 60.50674)"
        stroke="currentColor"
        strokeWidth="7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default IconLogo;
